import fetch from "isomorphic-fetch"
import { ApolloClient, InMemoryCache } from "@apollo/client"

export const apolloClient = (location) => {
  return new ApolloClient({
    uri: `${process.env.GATSBY_CRAFTGQL_URL}${location.search}`,
    fetch,
    cache: new InMemoryCache(),
  })
}
