import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "./src/utils/apolloClient"

import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./src/styles/site.css"

export const wrapPageElement = ({ element, props }) => {
  const { location } = props
  if (location.pathname.startsWith("/live-preview/")) {
    return (
      <ApolloProvider client={apolloClient(location)}>{element}</ApolloProvider>
    )
  }
  return element
}
